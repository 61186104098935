<template>
  <div class='row'>
    <div class='col-lg-12'>
      <div class='card card-top card-top-primary'>
        <div class='card-header d-flex justify-content-between align-items-center'>
          <vb-headers-card-header :data="{ title: 'Sıkça Sorulan Sorular' }" />
          <a-button class='btn btn-primary btn-with-addon text-nowrap' @click='addFaqVisible = true'>
              <span class='btn-addon'>
                <em class='btn-addon-icon fe fe-plus-circle'></em>
              </span>
            Yeni Soru Ekle
          </a-button>
        </div>
        <div class='card-body'>
          <div class='table-responsive text-nowrap'>
            <a-table
              :loading='loading'
              :columns='columns'
              :data-source='faqs'
              :pagination='false'
              :row-key='(record, index) => (index + 9).toString(36) + index'
            >
              <template #order='{ record }'>
                <a-button
                  class='btn btn-warning mr-2'
                  shape='circle'
                  @click.stop='() => changeOrderFaq(record, -1)'
                >
                  <em class='fe fe-arrow-up' />
                </a-button>

                <a-button
                  class='btn btn-warning'
                  shape='circle'
                  @click.stop='() => changeOrderFaq(record, 1)'
                >
                  <em class='fe fe-arrow-down' />
                </a-button>
              </template>

              <template #operation='{ record }'>
                <a-button
                  class='btn btn-primary mr-2'
                  shape='circle'
                  @click.stop='() => showModal(record)'
                >
                  <em class='fe fe-settings' />
                </a-button>

                <a-popconfirm
                  title='Soruyu Silmek İstediğinize Emin Misiniz?'
                  ok-text='Evet'
                  cancel-text='Hayır'
                  @confirm='deleteFaq(record)'
                  @click.stop
                >
                  <a-button shape='circle' class='btn btn-danger' @click.stop>
                    <em class='fe fe-trash' />
                  </a-button>
                </a-popconfirm>
              </template>
            </a-table>
          </div>
        </div>
      </div>
    </div>

    <a-modal v-model:visible='visible' centered width='80%' title='Soru Düzenle'>
      <template #footer>
        <a-button key='back' @click='visible = false'>Kapat</a-button>
        <a-button key='submit' class='btn btn-success px-5' :loading='loading' @click='saveFaq' :disabled='!enableChangesSaveButton'>
          Kaydet
        </a-button>
      </template>

      <div v-if='selectedIndex !== null'>
        <a-form-item>
          <a-input placeholder='Soru' name='title' v-model:value='initialFaqs[selectedIndex].title' />
        </a-form-item>
        <a-form-item :class='$style.editor'>
          <v-md-editor placeholder='Cevap' v-model='initialFaqs[selectedIndex].content' height='400px'></v-md-editor>
        </a-form-item>
      </div>
    </a-modal>

    <a-modal v-model:visible='addFaqVisible' centered width='80%' title='Soru Ekle'>
      <template #footer>
        <a-button key='back' @click='addFaqVisible = false'>Kapat</a-button>
        <a-button key='submit' class='btn btn-success px-5' :loading='loading' @click='addFaq' :disabled='newFaq.title === "" || newFaq.content === ""'>
          Kaydet
        </a-button>
      </template>

      <a-form-item>
        <a-input placeholder='Soru' name='title' v-model:value='newFaq.title' />
      </a-form-item>
      <a-form-item :class='$style.editor'>
        <v-md-editor placeholder='Cevap' v-model='newFaq.content' style='padding: 0' height='400px'></v-md-editor>
      </a-form-item>
    </a-modal>
  </div>
</template>

<script>
import { appInfoAdministration } from '../../services/api-service'

export default {
  name: 'SSSYonetimi',
  data() {
    const columns = [
      {
        title: 'Soru',
        dataIndex: 'title',
        ellipsis: true,
      },
      {
        title: 'Cevap',
        dataIndex: 'content',
        ellipsis: true,
      },
      {
        title: 'Sıralama',
        dataIndex: 'order',
        slots: { customRender: 'order' },
        class: 'text-right',
        width: '100px',
      },
      {
        title: 'Yönet',
        dataIndex: 'operation',
        slots: { customRender: 'operation' },
        class: 'text-right',
        width: '100px',
      },
    ]

    return {
      visible: false,
      addFaqVisible: false,
      columns,
      loading: false,
      faqs: [],
      initialFaqs: [],
      selectedIndex: null,
      newFaq: { title: '', content: '' },
    }
  },
  computed: {
    enableChangesSaveButton() {
      return this.selectedIndex !== null && ((this.initialFaqs[this.selectedIndex].title !== this.faqs[this.selectedIndex].title) || (this.initialFaqs[this.selectedIndex].content !== this.faqs[this.selectedIndex].content))
    },
  },
  watch: {
    visible(value) {
      if (!value) {
        this.selectedIndex = null
        this.initialFaqs = JSON.parse(JSON.stringify(this.faqs))
      }
    },
  },
  mounted() {
    this.loadFaqs()
  },
  methods: {
    async showModal(object) {
      this.selectedIndex = this.faqs.indexOf(object)
      this.visible = true
    },
    async loadFaqs() {
      this.loading = true
      try {
        const result = await appInfoAdministration.getAppStatusForAdministration()
        this.faqs = result.data.faqs
        this.initialFaqs = JSON.parse(JSON.stringify(result.data.faqs))
      } catch {
      }
      this.loading = false
    },
    async saveFaq() {
      this.loading = true
      try {
        await appInfoAdministration.changeFaq({ faqsListItems: this.initialFaqs })
        this.faqs = JSON.parse(JSON.stringify(this.initialFaqs))
        this.visible = false
      } catch {
      }
      this.loading = false
    },
    async addFaq() {
      this.loading = true
      try {
        this.initialFaqs.push(this.newFaq)
        await appInfoAdministration.changeFaq({ faqsListItems: this.initialFaqs })
        this.faqs = JSON.parse(JSON.stringify(this.initialFaqs))
        this.newFaq = { title: '', content: '' }
        this.addFaqVisible = false
      } catch {
      }
      this.loading = false
    },
    async deleteFaq(object) {
      this.loading = true
      try {
        this.initialFaqs.splice(this.initialFaqs.indexOf(object), 1)
        await appInfoAdministration.changeFaq({ faqsListItems: this.initialFaqs })
        this.faqs = JSON.parse(JSON.stringify(this.initialFaqs))
      } catch {
      }
      this.loading = false
    },
    async changeOrderFaq(object, direction) {
      this.loading = true
      try {
        const index = this.faqs.indexOf(object)
        const newIndex = index + direction

        if (newIndex < 0) {
          this.initialFaqs.push(this.initialFaqs.splice(index, 1)[0])
        } else if (newIndex > this.initialFaqs.length - 1) {
          this.initialFaqs.unshift(this.initialFaqs.splice(index, 1)[0])
        } else {
          this.initialFaqs.splice(newIndex, 0, this.initialFaqs.splice(index, 1)[0])
        }

        await appInfoAdministration.changeFaq({ faqsListItems: this.initialFaqs })
        this.faqs = JSON.parse(JSON.stringify(this.initialFaqs))
      } catch {
        this.initialFaqs = JSON.parse(JSON.stringify(this.faqs))
      }
      this.loading = false
    },
  },
}
</script>
<style lang='scss' module>
@import './style.module.scss';
</style>
